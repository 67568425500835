import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { persistStore } from 'redux-persist'

import { persistedReducer } from './rootReducer'

import { authApi } from '@/auth/services/auth.service'
import { inscriptionApi } from '@/inscription/services/inscription.service'
import { inscriptionQueryApi } from '@/inscription/services/inscriptionQuery.service'
import { cohortApi } from '@/education/cohorts/services/cohort.service'
import { studentMutation } from '@/education/cohorts/services/student-mutation.service'
import { courseApi } from '@/education/cohorts/services/course.service'
import { assignmentApi } from '@/education/assignments/services/assignment.service'
import { assignmentActionsApi } from '@/education/assignments/services/assignmentActions.service'
import { exerciseApi } from '@/education/assignments/services/exercise.service'
import { profileApi } from '@/education/cohorts/services/profile.service'
import { userMutationApi } from '../services/user-mutation.service'
import { userQueryApi } from '../services/userQuery.service'
import { billingQueryApi } from '@/education/subscriptions/services/billing-query.service'
import { billingMutationApi } from '@/education/subscriptions/services/billing-mutation.service'
import { contractQueryApi } from '@/education/subscriptions/services/contract-query.service'
import { contractMutationApi } from '@/education/subscriptions/services/contract-mutation.service'
import { blogQueryApi } from '@/education/edpresso/services/blog-query.service'

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      authApi.middleware,
      inscriptionApi.middleware,
      inscriptionQueryApi.middleware,
      cohortApi.middleware,
      studentMutation.middleware,
      courseApi.middleware,
      assignmentApi.middleware,
      assignmentActionsApi.middleware,
      exerciseApi.middleware,
      userQueryApi.middleware,
      userMutationApi.middleware,
      contractQueryApi.middleware,
      blogQueryApi.middleware,
      contractMutationApi.middleware,
      billingQueryApi.middleware,
      billingMutationApi.middleware,
      profileApi.middleware,
    ),
})

export const persistedStore = persistStore(store)

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
