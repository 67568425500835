import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'

import { CACHE_TIME } from '@/shared/constants'
import {
  COHORT_STATUS,
  COHORT_TYPE,
  CohortProduct,
  Currencies,
  PAYMENT_METHODS_TYPES,
  PLANS_TYPES,
  Product,
  PRODUCTS_TYPES,
} from '@/shared/types'
import { DateTime } from '@/shared/helpers'

type GetNucbaProductsResponse = {
  products: {
    id: string
    name: string
    type: PRODUCTS_TYPES
    plans: {
      id: string
    }[]
    duoPlans: {
      id: string
    }[]
    productsCohorts: {
      cohort: {
        code: string
        status: COHORT_STATUS
      }
    }[]
  }[]
}

type GetNucbaProductsTransformResponse = {
  [Property in PRODUCTS_TYPES]: Product[]
}

type GetCohortsWithProductAvailableResponse = {
  cohorts: {
    id: string
    code: string
    days: number[]
    type: COHORT_TYPE
    _products_cohorts: {
      product: {
        type: PRODUCTS_TYPES
        _product_courses: {
          course: {
            classes: {
              cohort_classes: {
                date: string
                cohort: {
                  code: string
                }
              }[]
            }[]
          }
        }[]
      }
    }[]
  }[]
}

type GetCohortsWithProductAvailableTransformResponse = {
  cohorts: CohortProduct[]
}

type GetCohortProductCoursesResponse = {
  _product_courses: {
    course: {
      id: string
      name: string
      isAsync: boolean
      classes: {
        cohort_classes: {
          date: string
        }[]
      }[]
    }
  }[]
}

type GetNucbaPaymentMethodsResponse = {
  payment_methods: {
    id: string
    name: string
    type: PAYMENT_METHODS_TYPES
    paymentPolicy: string
  }[]
}

type GetNucbaPaymentMethodsTransformResponse =
  GetNucbaPaymentMethodsResponse['payment_methods']

type GetPlansWithPaymentMethodResponse = {
  plans: {
    id: string
    name: string
    installments: number
    duration: number
    currency: Currencies
    price: number
    description: string
    type: PLANS_TYPES
    isDuo: boolean
    paymentMethod: {
      id: string
      name: string
      type: PAYMENT_METHODS_TYPES
      paymentPolicy: string
    }
    plansCampaigns: {
      campaign: {
        name: string
        discount: number
      }
    }[]
  }[]
}

type GetPlansWithPaymentMethodTransformResponse = {
  id: string
  name: string
  installments: number
  duration: number
  currency: Currencies
  price: number
  description: string
  type: PLANS_TYPES
  isDuo: boolean
  paymentMethod: {
    id: string
    name: string
    type: PAYMENT_METHODS_TYPES
    paymentPolicy: string
  }
  campaign: {
    name: string
    discount: number
  } | null
}[]

type GetNucbaCampaigns = {
  campaigns: {
    id: string
    name: string
    description: string
  }[]
}

type GetNucbaProductResponse = {
  products: {
    id: string
    name: string
    type: PRODUCTS_TYPES
  }[]
}

type GetNucbaProductTransformResponse =
  | GetNucbaProductResponse['products'][number]
  | undefined

type GetNucbaPlanResponse = {
  plans: {
    id: string
    name: string
    installments: number
    duration: number
    currency: Currencies
    price: number
    description: string
    type: PLANS_TYPES
    isDuo: boolean
    paymentMethod: {
      id: string
      name: string
      type: PAYMENT_METHODS_TYPES
      paymentPolicy: string
    }
    _plans_campaigns: {
      campaign: {
        discount: number
        name: string
      }
    }[]
  }[]
}

type GetNucbaPlanTransformResponse =
  | GetNucbaPlanResponse['plans'][number]
  | undefined

type GetNucbaPaymentMethodResponse = {
  payment_methods: {
    id: string
    name: string
    type: PAYMENT_METHODS_TYPES
    paymentPolicy: string
  }[]
}

type GetNucbaPaymentMethodTransformResponse =
  | GetNucbaPaymentMethodResponse['payment_methods'][number]
  | undefined

export const inscriptionQueryApi = createApi({
  reducerPath: 'inscriptionQueryApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: headers => {
      headers.set('public-access', 'true')

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  endpoints: builder => ({
    getNucbaProducts: builder.query<GetNucbaProductsTransformResponse, void>({
      query: () => ({
        document: gql`
          query NucbaProducts {
            products(
              where: { isSellable: { _eq: true } }
              order_by: { createdAt: asc }
            ) {
              id
              name
              type
              plans(where: { enabled: { _eq: true } }, limit: 1) {
                id
              }
              duoPlans: plans(
                where: { enabled: { _eq: true }, isDuo: { _eq: true } }
                limit: 1
              ) {
                id
              }
              productsCohorts: _products_cohorts(
                where: { cohort: { status: { _eq: "INSCRIPCIONES_ABIERTAS" } } }
                limit: 1
              ) {
                cohort {
                  code
                  status
                }
              }
            }
          }
        `,
      }),
      transformResponse: (data: GetNucbaProductsResponse) =>
        data.products.reduce(
          (
            acc: any,
            {
              productsCohorts,
              ...product
            }: GetNucbaProductsResponse['products'][number],
          ) => {
            const hasAvailableCohort = productsCohorts.length > 0
            const hasPlansEnable = product.plans.length > 0
            const hasDuoPlan = product.duoPlans.length > 0

            const currentProduct: Product = {
              id: product.id,
              name: product.name,
              type: product.type,
              hasAvailableCohort,
              hasPlansEnable,
              hasDuoPlan,
            }

            if (product.type === PRODUCTS_TYPES.CARRERA) {
              acc.CARRERA.push(currentProduct)
            }

            if (product.type === PRODUCTS_TYPES.CURSO) {
              acc.CURSO.push(currentProduct)
            }

            if (product.type === PRODUCTS_TYPES.ON_DEMAND) {
              acc.ON_DEMAND.push(currentProduct)
            }

            return acc
          },
          { CARRERA: [], CURSO: [], ON_DEMAND: [] },
        ),
    }),

    getCohortsWithProductAvailable: builder.query<
      GetCohortsWithProductAvailableTransformResponse,
      { productId: string }
    >({
      query: ({ productId }) => ({
        document: gql`
          query CohortsWithProductAvailable($productId: String) {
            cohorts(
              where: {
                status: { _eq: "INSCRIPCIONES_ABIERTAS" }
                _products_cohorts: { B: { _eq: $productId } }
              }
            ) {
              id
              code
              days
              type
              _products_cohorts(where: { B: { _eq: $productId } }) {
                product {
                  type
                  _product_courses(
                    limit: 1
                    order_by: { course: { order: asc } }
                  ) {
                    course {
                      classes(limit: 1, order_by: { order: asc }) {
                        cohort_classes(
                          where: {
                            cohort: {
                              _products_cohorts: { B: { _eq: $productId } }
                              status: { _eq: "INSCRIPCIONES_ABIERTAS" }
                            }
                          }
                        ) {
                          date
                          cohort {
                            code
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          productId,
        },
      }),
      transformResponse: (data: GetCohortsWithProductAvailableResponse) => {
        const cohorts = data.cohorts
          .map(({ id, code, days, type, _products_cohorts }) => ({
            id,
            code,
            days,
            type,
            startDate:
              _products_cohorts[0].product._product_courses[0].course.classes[0].cohort_classes.find(
                cohortClass => cohortClass.cohort.code === code,
              )?.date,
          }))
          .filter(
            cohort =>
              !!cohort.startDate || cohort.type === COHORT_TYPE.ON_DEMAND,
          )
          .filter(cohort => {
            if (cohort.type === COHORT_TYPE.ON_DEMAND) return true

            const minDate = DateTime.today().substractDays(45)
            const startDate = DateTime.createFromDate(
              new Date(cohort.startDate!),
            )

            return startDate.greaterThanOrEqual(minDate)
          }) as CohortProduct[]

        return { cohorts }
      },
    }),
    getCohortProductCourses: builder.query<
      GetCohortProductCoursesResponse,
      { productId: string; code: string }
    >({
      query: ({ productId, code }) => ({
        document: gql`
          query CohortProductCourses($productId: String, $code: String) {
            _product_courses(
              order_by: { course: { order: asc } }
              where: { B: { _eq: $productId } }
            ) {
              course {
                id
                name
                isAsync
                classes(limit: 1, order_by: { order: asc }) {
                  cohort_classes(where: { cohort: { code: { _eq: $code } } }) {
                    date
                  }
                }
              }
            }
          }
        `,
        variables: {
          productId,
          code,
        },
      }),
    }),
    getNucbaPaymentMethods: builder.query<
      GetNucbaPaymentMethodsTransformResponse,
      { productId: string }
    >({
      query: ({ productId }) => ({
        document: gql`
          query PaymentMethods($productId: String) {
            payment_methods(
              where: {
                plans: {
                  productId: { _eq: $productId }
                  enabled: { _eq: true }
                }
              }
              order_by: { createdAt: asc }
            ) {
              id
              name
              type
              paymentPolicy
            }
          }
        `,
        variables: {
          productId,
        },
      }),
      transformResponse: (data: GetNucbaPaymentMethodsResponse) =>
        data.payment_methods,
    }),
    getPlansWithPaymentMethod: builder.query<
      GetPlansWithPaymentMethodTransformResponse,
      { productId: string; paymentMethodId: string }
    >({
      query: ({ productId, paymentMethodId }) => ({
        document: gql`
          query PlansWithPaymentMethod(
            $paymentMethodId: String
            $productId: String
          ) {
            plans(
              where: {
                productId: { _eq: $productId }
                paymentMethodId: { _eq: $paymentMethodId }
                enabled: { _eq: true }
              }
            ) {
              id
              name
              installments
              duration
              currency
              price
              description
              type
              isDuo
              paymentMethod: payment_method {
                id
                name
                type
                paymentPolicy
              }
              plansCampaigns: _plans_campaigns(
                where: { campaign: { current: { _eq: true } } }
              ) {
                campaign {
                  name
                  discount
                }
              }
            }
          }
        `,
        variables: {
          productId,
          paymentMethodId,
        },
      }),
      transformResponse: (data: GetPlansWithPaymentMethodResponse) => {
        return data.plans.map(({ plansCampaigns, ...plan }) => ({
          ...plan,
          campaign: plansCampaigns.at(0)?.campaign ?? null,
        }))
      },
    }),
    getNucbaCampaigns: builder.query<GetNucbaCampaigns, void>({
      query: () => ({
        document: gql`
          query NucbaCampaigns {
            campaigns(where: { current: { _eq: true } }) {
              id
              name
              description
            }
          }
        `,
      }),
    }),
    getNucbaProduct: builder.query<
      GetNucbaProductTransformResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        document: gql`
          query getNucbaProduct($id: String) {
            products(where: { isSellable: { _eq: true }, id: { _eq: $id } }) {
              id
              name
              type
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (data: GetNucbaProductResponse) => data.products.at(0),
    }),
    getNucbaPlan: builder.query<GetNucbaPlanTransformResponse, { id: string }>({
      query: ({ id }) => ({
        document: gql`
          query getNucbaPlan($id: String) {
            plans(where: { id: { _eq: $id } }) {
              id
              name
              installments
              duration
              currency
              price
              description
              type
              isDuo
              paymentMethod: payment_method {
                id
                name
                type
                paymentPolicy
              }
              _plans_campaigns(
                where: { campaign: { current: { _eq: true } } }
              ) {
                campaign {
                  discount
                  name
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (data: GetNucbaPlanResponse) => data.plans.at(0),
    }),
    getNucbaPaymentMethod: builder.query<
      GetNucbaPaymentMethodTransformResponse,
      { id: string }
    >({
      query: ({ id }) => ({
        document: gql`
          query getNucbaPaymentMethod($id: String) {
            payment_methods(where: { id: { _eq: $id } }) {
              id
              name
              type
              paymentPolicy
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (data: GetNucbaPaymentMethodResponse) =>
        data.payment_methods.at(0),
    }),
  }),
})

export const {
  useGetNucbaProductsQuery,
  useGetCohortsWithProductAvailableQuery,
  useGetCohortProductCoursesQuery,
  useGetNucbaPaymentMethodsQuery,
  useGetPlansWithPaymentMethodQuery,
  useGetNucbaCampaignsQuery,
  useGetNucbaProductQuery,
  useGetNucbaPlanQuery,
  useGetNucbaPaymentMethodQuery,
  usePrefetch: useInscriptionPrefetch,
} = inscriptionQueryApi
