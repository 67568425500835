import React from 'react'
import { Skeleton, Box, Stack, SkeletonText } from '@chakra-ui/react'

export const PostDetailLoader = () => {
  return (
    <Stack mx='auto' w='full' maxW={980} py={12} spacing={8}>
      <Skeleton height='400px' width='100%' borderRadius='md' />
      <Skeleton height='40px' width='70%' borderRadius='md' mb={10} />
      <Box>
        <SkeletonText mt='4' noOfLines={6} spacing='6' skeletonHeight='20px' />
        <Skeleton height='20px' width='50%' borderRadius='md' mt={8} mb={6} />
        <Skeleton height='20px' width='70%' borderRadius='md' mt={6} mb={6} />
        <Skeleton height='20px' width='90%' borderRadius='md' mt={6} mb={6} />
      </Box>
    </Stack>
  )
}
