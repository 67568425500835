import React from 'react'
import { motion } from 'framer-motion'
import { Stack, Box, Heading, Text } from '@chakra-ui/react'
import { SectionPickerLoader } from '@/education/shared/components/loaders/SectionPickLoader'
import styled from '@emotion/styled'

interface SectionPickerProps {
  onPick: (slug: string) => void
  activeSection: string
  groups: {
    name: string
    slug: string
    spaces: {
      name: string
      slug: string
    }[]
  }[]
}

const SectionBlogContainerWithScroll = styled(Stack)`
  width: 95%;
  height: 90vh;
  margin: 0.5rem 0;
  padding-right: 1.5rem;

  overflow-y: ${({ overflowY }) => overflowY || 'scroll'};
  overflow-x: hidden; /* Elimina el scroll horizontal */

  &::-webkit-scrollbar {
    width: 8px; /* Reduce el ancho de la barra */
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`

export const SectionPicker: React.FC<SectionPickerProps> = ({
  onPick,
  activeSection,
  groups,
}) => {
  if (!groups) return <span>No hay grupos</span>

  return (
    <SectionBlogContainerWithScroll>
      <Stack spacing={6} w='min-content'>
        {groups.map(group => (
          <Stack key={group.slug} spacing={4}>
            <Heading size='md'>{group.name}</Heading>
            {group.spaces.map(space => (
              <PickerOption
                key={space.slug}
                label={space.name}
                onPick={() => onPick(space.slug)}
                isActive={activeSection === space.slug}
              />
            ))}
          </Stack>
        ))}
      </Stack>
    </SectionBlogContainerWithScroll>
  )
}

type PickerOptionProps = {
  label: string
  onPick: () => void
  isActive: boolean
}

const PickerOption: React.FC<PickerOptionProps> = ({
  label,
  onPick,
  isActive,
}) => {
  return (
    <Box
      as={motion.div}
      whileTap={{ scale: 0.95 }}
      onClick={onPick}
      cursor='pointer'
      p={2}
      borderRadius='md'
      bg={isActive ? 'blue.600' : 'gray.700'}
      color='white'
      textAlign='center'
      display='flex'
      alignItems='center'
      fontSize={{ base: 'xs', md: 'sm' }}
      boxShadow='lg'
      width={{ base: '150px', sm: '180px', md: '200px' }}
      maxW='100%'
      _hover={{ bg: 'blue.700' }}
    >
      {label}
    </Box>
  )
}
