import React from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HStack, Stack, Text } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'
import { stringify } from 'qs'

import { ListOfClasses } from '../components/ListOfClasses'
import { AboutCourse } from '../components/AboutCourse'
import { ContentTemplate } from '../../shared/template/ContentTemplate'
import { ModulePresentation } from '../../shared/components/ModulePresentation'
import { useGetCourseDetailsQuery } from '../services/course.service'

import { Isologo } from '@/shared/components/Isologo'
import { Hero } from '@/shared/components/Hero'
import { Breadcrumb } from '@/shared/components/Breadcrumb'
import { useRedirect, useStudent } from '@/shared/hooks'
import { useSessionState } from '@/shared/features/session.slice'
import { CertificadoPdf } from '../components/CertificadoPdf'
import { HeroLoader } from '@/education/shared/components/loaders/HeroLoader'
import { ModalVideoPlayer } from '@/shared/components/ModalVideoPlayer'
import { useGetCohortZoomIdQuery } from '../services/cohort.service'
import { openInNewTab } from '@/shared/utils'
import { HeaderNovu } from '@/shared/components/Novu'
import { COHORT_TYPE } from '@/shared/types'

export const Course = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { code } = useParams()

  const { isStaff, id, firstname, lastname } = useSessionState()
  const { expelledAt } = useStudent(code!)

  useRedirect('..', 'courseId')

  const { data: zoomData } = useGetCohortZoomIdQuery({ code: code! })

  const { data, isLoading } = useGetCourseDetailsQuery({
    courseId: state?.courseId,
    userId: id,
  })

  const teaserItem = {
    element: <ModalVideoPlayer videoId={data?.teaser || ''} text='Teaser' />,
  }

  return (
    <>
      <Hero image='https://res.cloudinary.com/dcatzxqqf/image/upload/v1679871403/Galileo-Platform/Images/banner/courseHero_ektcn3.webp'>
        <ContentTemplate
          h='100%'
          display='flex'
          flexDir='column'
          justifyContent='space-around'
        >
          <HStack w='95%' justify='space-between'>
            <Breadcrumb />
            <HStack>
              <HeaderNovu />
              <Isologo />
            </HStack>
          </HStack>
          {isLoading ? (
            <HeroLoader withItems />
          ) : (
            <HStack w='95%' justify='space-between' align='flex-end'>
              <Stack spacing={{ base: 2, '2xl': 5 }}>
                <ModulePresentation title={data?.name || ''} />
                {(state?.cohortType !== COHORT_TYPE.ON_DEMAND || isStaff) && (
                  <Button
                    maxW='min-content'
                    size='sm'
                    fontSize='sm'
                    borderRadius={30}
                    textTransform='uppercase'
                    onClick={
                      isStaff
                        ? () => navigate('entregas', { state })
                        : () =>
                            openInNewTab(
                              `https://us02web.zoom.us/j/${zoomData?.zoomId}?${stringify(
                                {
                                  uname: `${firstname} ${lastname}`,
                                },
                              )}`,
                            )
                    }
                    isDisabled={!!expelledAt}
                  >
                    {isStaff ? 'Ver Entregas' : 'Ir al vivo'}
                  </Button>
                )}
              </Stack>
              <Stack w='40%' maxW='550px' spacing={2}>
                <AboutCourse
                  title={data?.name || ''}
                  description={data?.description || ''}
                  items={
                    data?.Certificates.length
                      ? [
                          teaserItem,
                          data.Certificates.map(
                            ({ type, course, product }) => ({
                              element: (
                                <PDFDownloadLink
                                  document={
                                    <CertificadoPdf
                                      title={
                                        type === 'PARTIAL'
                                          ? course.name.split(' ').join('_')
                                          : product.name.split(' ').join('_')
                                      }
                                      firstName={
                                        data?.Certificates[0].user.profiles[0]
                                          .firstname
                                      }
                                      lastName={
                                        data?.Certificates[0].user.profiles[0]
                                          .lastname
                                      }
                                      isPartial={type === 'PARTIAL'}
                                      createdAt={
                                        data?.Certificates[0].createdAt ||
                                        new Date()
                                      }
                                    />
                                  }
                                  fileName={`Certificado_${
                                    type === 'PARTIAL'
                                      ? course.name.split(' ').join('_')
                                      : product.name.split(' ').join('_')
                                  }`}
                                >
                                  <Text _hover={{ textDecor: 'underline' }}>
                                    Descargar certificado
                                  </Text>
                                </PDFDownloadLink>
                              ),
                            }),
                          ),
                        ].flat()
                      : [teaserItem]
                  }
                />
              </Stack>
            </HStack>
          )}
        </ContentTemplate>
      </Hero>

      <ListOfClasses />
    </>
  )
}
