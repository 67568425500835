import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Heading, Stack, Text } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { slugifyCourseName } from '../../shared/utils'
import {
  useGetCohortNextAssignmentByStudentQuery,
  useGetCohortNextClassQuery,
} from '../services/cohort.service'
import { useAssignmentPrefetch } from '@/education/assignments/services/assignment.service'
import { BannerLoader } from '@/education/shared/components/loaders/BannerLoader'
import { useStudent } from '@/shared/hooks/useStudent'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'

type BannerProps = {
  bgImage: string
}

export const Banner = ({ bgImage }: BannerProps) => {
  const navigate = useNavigate()
  const { code } = useParams()
  const { studentId } = useStudent(code!)

  const { isStaff } = useAppSelector(sessionState)

  const { data: nextAssignment, isLoading: nextAssignmentIsLoading } =
    useGetCohortNextAssignmentByStudentQuery(
      { studentId, code: code! },
      {
        skip: isStaff,
      },
    )

  const { data: nextClass, isLoading: nextClassIsLoading } =
    useGetCohortNextClassQuery(code!, {
      skip: !isStaff,
    })

  const prefetchAssignmentDetails = useAssignmentPrefetch(
    'getAssignmentDetails',
    {
      force: false,
    },
  )

  return nextAssignmentIsLoading || nextClassIsLoading ? (
    <BannerLoader />
  ) : nextAssignment || nextClass ? (
    <Stack
      flexGrow={1}
      maxW='800px'
      h='full'
      justify='space-between'
      py={4}
      px={8}
      bg={`url(${bgImage}) center/cover no-repeat ,  linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 56.25%, #000000 92.64%)`}
      borderRadius={23}
    >
      <Stack>
        <Heading as='h3' fontSize={{ base: 'xl', '2xl': '3xl' }}>
          📋{' '}
          {isStaff
            ? 'Todavía no está disponible tu próxima clase 😣'
            : 'Acá encontrarás todas tus entregas'}
        </Heading>
        <Text fontSize={{ base: 'sm', '2xl': 'lg' }}>
          {isStaff
            ? 'En los próximos días se publicará tu próxima clase'
            : '🎓 Hace click para verlas'}
        </Text>
        <Button
          alignSelf='flex-end'
          paddingInline={5}
          fontSize='sm'
          borderRadius={30}
          textTransform='uppercase'
          onClick={() => navigate(`./asignaciones/${studentId}`)}
        >
          Ir a mis entregas
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Stack
      flexGrow={1}
      h='full'
      justify='space-around'
      paddingInline={8}
      bg={`url(${bgImage}) center/cover no-repeat, linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 56.25%, #000000 92.64%)`}
      borderRadius={23}
    >
      <Stack>
        <Heading as='h3' fontSize={{ base: 'xl', '2xl': '3xl' }}>
          📋{' '}
          {isStaff
            ? 'Todavía no está disponible tu próxima clase 😣'
            : 'Acá encontrarás todas tus entregas'}
        </Heading>
        <Text fontSize={{ base: 'sm', '2xl': 'lg' }}>
          {isStaff
            ? 'En los próximos días se publicará tu próxima clase'
            : '🎓 Hace click para verlas'}
        </Text>
        <Button
          alignSelf='flex-end'
          paddingInline={5}
          fontSize='sm'
          borderRadius={30}
          textTransform='uppercase'
          onClick={() => navigate(`./asignaciones/${studentId}`)}
        >
          Ir a mis entregas
        </Button>
      </Stack>
    </Stack>
  )
}
