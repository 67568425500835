import React from 'react'
import { useParams } from 'react-router-dom'
import { HStack, Stack } from '@chakra-ui/react'
import { AlertInfo } from '@nucba-coding/nucba-ui'

import { Banner } from './Banner'
import { PageHeading } from '../../shared/components/PageHeading'
import { Breadcrumb } from '@/shared/components/Breadcrumb'
import { useGetCohortAnnouncementQuery } from '../services/cohort.service'
import { AlertInfoLoader } from '@/education/shared/components/loaders/AlertInfoLoader'
import { useStudent } from '@/shared/hooks'
import { HeaderNovu } from '@/shared/components/Novu'

export const CohortInfo = () => {
  const { code } = useParams()

  const { expelledAt } = useStudent(code!)

  const { data: announcement, isLoading } = useGetCohortAnnouncementQuery({
    code: code!,
  })

  return (
    <Stack
      height={{
        base: '45vh',
        '2xl': '40vh',
      }}
      justify='center'
    >
      <Stack w='96%'>
        <Breadcrumb />

        <HStack justify='space-between'>
          <PageHeading fontSize={{ base: '2xl', '2xl': '2.5rem' }}>
            Estas en la camada #{code} 🎓
          </PageHeading>
          <HeaderNovu />
        </HStack>

        <HStack gap={3} h={{ base: 130, '2xl': 160 }}>
          <Banner bgImage='https://res.cloudinary.com/dcatzxqqf/image/upload/v1679871402/Galileo-Platform/Images/banner/assigmentBannerBlur_r7edoz.webp' />
          {isLoading ? (
            <AlertInfoLoader />
          ) : (
            <AlertInfo
              fontSize='sm'
              h='100%'
              title={
                expelledAt
                  ? 'No formas parte de la camada 😢'
                  : announcement?.title || `Bienvenido a la camada ${code!}`
              }
              content={
                expelledAt
                  ? 'Si crees que es un error o tenes alguna consulta, comunícate con secretaría. '
                  : announcement?.content ||
                    'No te olvides de sumarte a la comunidad de NUCBA en Discord 🎓'
              }
              url={
                expelledAt
                  ? 'https://api.whatsapp.com/send?phone=5491123946509&text=Hola,%20quiero%20comunicarme%20con%20secretaria%20acad%C3%A9mica%20de%20Nucba!'
                  : announcement?.url || 'https://discord.gg/mSBRC25sTY'
              }
            />
          )}
        </HStack>
      </Stack>
    </Stack>
  )
}
