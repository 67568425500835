import { RootState } from '@/shared/app/store'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { cohortApi } from './cohort.service'
import { ATTENDANCE_STATUS } from '@/shared/types'

export const studentMutation = createApi({
  reducerPath: 'studentMutation',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/estudiantes`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  endpoints: builder => ({
    viewedRecording: builder.mutation<
      any,
      {
        studentId: string
        body: {
          classId: string
        }
        payload: {
          courseId: string
          code: string
        }
      }
    >({
      query: ({ studentId, body }) => ({
        url: `/${studentId}/grabacion-vista`,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: (
        { studentId, body: { classId }, payload: { courseId, code } },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          cohortApi.util.updateQueryData(
            'getCohortClassesByCourse',
            { courseId, studentId, code },
            draft => ({
              ...draft,
              cohort_classes: draft.cohort_classes.map(cohortClass =>
                cohortClass.id === classId
                  ? {
                      ...cohortClass,
                      student_attendances: [
                        { status: ATTENDANCE_STATUS.RECORDING_VIEWED },
                      ],
                    }
                  : cohortClass,
              ),
            }),
          ),
        )

        queryFulfilled.catch(patchResult.undo)
      },
    }),
  }),
})

export const { useViewedRecordingMutation } = studentMutation
