import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Stack, Divider, Box } from '@chakra-ui/react'
import {
  NucDiscord,
  NucEnvelope,
  NucFile,
  NucHammer,
  NucHome,
  NucLogout,
  NucStar,
  NucUser,
  NucWrite,
  NucYoutube,
  ToggleButton,
} from '@nucba-coding/nucba-ui'
import { motion, useCycle } from 'framer-motion'

import { SidebarStack } from './SidebarStack'
import { SidebarItem } from './SidebarItem'
import { SidebarAvatar } from './SidebarAvatar'

import { useAppDispatch } from '../../app/hooks'
import { endSession } from '../../features/session.slice'
import { openInNewTab } from '@/shared/utils'
import { SidebarBackOverlay } from './SidebarBackOverlay'

export const Sidebar = () => {
  const navigate = useNavigate()

  const [open, cyclyOpen] = useCycle(false, true)
  const [width, setWidth] = useState(100)
  const dispatch = useAppDispatch()

  useEffect(() => {
    setWidth(open ? 250 : 100)
  }, [open])

  const handleClick = () => {
    cyclyOpen()
  }

  return (
    <>
      {open && <SidebarBackOverlay toogleSidebar={handleClick} />}
      <Box
        as={motion.aside}
        animate={{ width }}
        width={width}
        position='fixed'
        paddingInline={5}
        bgColor='#111010'
        zIndex={100}
      >
        <ToggleButton
          position='absolute'
          right='-15px'
          top='6.5%'
          w={7}
          h={7}
          toggle={() => cyclyOpen()}
          //Asquerosidad agregada para que el icono de flecha rote al clickear el overlay hasta poder solucionar de manera definitiva
          transform={
            open ? 'rotate(180deg) !important' : 'rotate(0deg) !important'
          }
        />
        <Stack
          as={motion.div}
          initial='closed'
          animate='open'
          h='100vh'
          justify='space-evenly'
          divider={<Divider />}
        >
          <SidebarStack isOpen={open}>
            <SidebarAvatar isOpen={open} />
            {/*  <SidebarItem
            isOpen={open}
            text='Notificaciones'
            element={<HeaderNovu />
            }
            onClick={() => {
              navigate('/')
            }}
          /> */}
            <SidebarItem
              isOpen={open}
              text='Inicio'
              element={<NucHome width='18' height='18' />}
              onClick={() => {
                navigate('/')
              }}
            />
          </SidebarStack>
          <SidebarStack isOpen={open}>
            <SidebarItem
              isOpen={open}
              text='Mis Suscripciones'
              element={<NucStar width='18' height='18' />}
              onClick={() => {
                navigate('/mis-suscripciones')
              }}
            />
            <SidebarItem
              isOpen={open}
              text='Blog'
              element={<NucWrite />}
              onClick={() => {
                navigate('/blog')
                open ? cyclyOpen() : null
              }}
            />
            {/*  <SidebarItem
              isOpen={open}
              text='Recursos'
              element={<NucHammer />}
              onClick={() => {
                openInNewTab('https://plataforma.nucba.io/s/tools/')
              }}
            /> */}
            <SidebarItem
              isOpen={open}
              text='Documentaciones'
              element={<NucFile />}
              onClick={() => {
                navigate('/blog?section=documentaciones')
                open ? cyclyOpen() : null
              }}
            />
          </SidebarStack>
          <SidebarStack isOpen={open}>
            <SidebarItem
              isOpen={open}
              text='Discord'
              element={<NucDiscord />}
              onClick={() => {
                openInNewTab('https://discord.com/invite/mSBRC25sTY')
              }}
            />
            <SidebarItem
              isOpen={open}
              text='Youtube'
              element={<NucYoutube />}
              onClick={() => {
                openInNewTab(
                  'https://www.youtube.com/channel/UC9yQdGlpksaB7MtN2nibFMw',
                )
              }}
            />
          </SidebarStack>
          <SidebarStack isOpen={open}>
            <Stack spacing={{ base: 3, '2xl': 7 }}>
              <SidebarItem
                isOpen={open}
                text='Perfil'
                element={<NucUser />}
                onClick={() => {
                  navigate('/perfil')
                }}
              />
            </Stack>
            <SidebarItem
              isOpen={open}
              text='Cerrar sesión'
              onClick={() => {
                dispatch(endSession())
              }}
              element={<NucLogout />}
            />
          </SidebarStack>
        </Stack>
      </Box>
    </>
  )
}
