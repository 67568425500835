import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from '@nucba-coding/nucba-ui'

import { slugifyCourseName } from '../utils'
import { useGetCohortClassesByCourseQuery } from '@/education/cohorts/services/cohort.service'
import { useAppDispatch } from '@/shared/app/hooks'
import { useViewedRecordingMutation } from '@/education/cohorts/services/student-mutation.service'
import { useStudent } from '@/shared/hooks'

type ButtonCompleteAndContinueProps = {
  courseId: string
}

export const ButtonCompleteAndContinue = ({
  courseId,
}: ButtonCompleteAndContinueProps) => {
  const navigate = useNavigate()
  const { code, classId, course } = useParams()
  const { studentId } = useStudent(code!)
  const dispatch = useAppDispatch()

  const [updateAttendance] = useViewedRecordingMutation()
  const { data } = useGetCohortClassesByCourseQuery({
    code: code!,
    courseId,
    studentId,
  })

  const handleCompleteAndContinue = () => {
    updateAttendance({
      studentId,
      body: { classId: classId! },
      payload: { courseId, code: code! },
    })

    const currentClassIndex =
      data?.cohort_classes.findIndex(item => item.id === classId) ?? 0

    const nextClass = data?.cohort_classes[currentClassIndex + 1]
    if (!nextClass) return

    navigate(
      `/camada/${code}/curso/${slugifyCourseName(course)}/clase/${nextClass?.id}`,
    )
  }

  return (
    <Button
      width={'fit-content'}
      fontSize={16}
      size='sm'
      w='min-content'
      onClick={handleCompleteAndContinue}
    >
      Completar y Continuar
    </Button>
  )
}
