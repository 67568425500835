import { createApi } from '@reduxjs/toolkit/query/react'
import { gql } from 'graphql-request'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { CACHE_TIME } from '../constants'
import { RootState } from '../app/store'
import {
  COHORT_TYPE,
  CONTRACT_STATUS,
  CohortProduct,
  INVOICE_STATUS,
  PAYMENT_METHODS_TYPES,
  PLANS_TYPES,
  SUBSCRIPTIONS_TYPES,
} from '../types'
import { CohortClassStatus } from '../enums'

type GetUserCohortsResponse = {
  users: {
    cohort_integrants: {
      id: string
      expelledAt: Date | null
      subscriptions: {
        id: string
      }[]
      duos: {
        id: string
      }[]
      cohort: {
        code: string
        id: string
        type: COHORT_TYPE
        cohort_classes: {
          status: string
          isAsync: boolean
          class: {
            course: {
              image: string
            }
          }
        }[]
      }
    }[]
  }[]
}

type GetUserCohortsTransformResponse = {
  cohort_integrants: {
    id: string
    expelledAt: Date | null
    subscription?: string
    duo?: string
    cohort: {
      code: string
      id: string
      type: COHORT_TYPE
      cohort_classes: {
        isAsync: boolean
        class: {
          course: {
            image: string
          }
        }
      }[]
    }
  }[]
}

type GetUserSubscriptionsResponse = {
  subscriptions: {
    userId: string
    id: string
    activatedAt: string
    finishAt: string
    status: SUBSCRIPTIONS_TYPES
    checkoutLink: string
    invoices: {
      id: string
      status: INVOICE_STATUS
    }[]
    duos: {
      id: string
      invitationEmail: string
    }[]
    plan: {
      name: string
      type: PLANS_TYPES
      installments: number
      product: {
        name: string
      }
      payment_method: {
        name: string
        type: PAYMENT_METHODS_TYPES
      }
    }
  }[]
}

type GetUserSubscriptionsTransformResponse =
  GetUserSubscriptionsResponse['subscriptions']

type GetUserDuosResponse = {
  duos: {
    id: string
    subscriptionOwner: string
    cohort_integrant?: {
      cohort: CohortProduct
    }
    subscription: {
      status: SUBSCRIPTIONS_TYPES
      plan: {
        product: {
          name: string
        }
      }
    }
  }[]
}

type GetUserDuosTransformResponse = GetUserDuosResponse['duos']

type GetUserPendingContractsResponse = {
  contracts: {
    id: string
    subscriptionId: string
    status: CONTRACT_STATUS
    userId: string
  }[]
}

type GetUserPendingContractsTransformResponse =
  | GetUserPendingContractsResponse['contracts'][number]
  | undefined

export const userQueryApi = createApi({
  reducerPath: 'userQueryApi',
  baseQuery: graphqlRequestBaseQuery({
    url: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).session.token

      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  keepUnusedDataFor: CACHE_TIME,
  tagTypes: ['Subscriptions', 'Contracts'],
  endpoints: builder => ({
    getUserCohorts: builder.query<GetUserCohortsTransformResponse, string>({
      query: id => ({
        document: gql`
          query UserCohorts($id: String_comparison_exp) {
            users(where: { id: $id }) {
              cohort_integrants {
                id
                expelledAt
                subscriptions {
                  id
                }
                duos {
                  id
                }
                cohort {
                  code
                  id
                  type
                  cohort_classes(
                    order_by: { class: { course: { order: asc } } }
                  ) {
                    status
                    isAsync
                    class {
                      course {
                        image
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          id: {
            _eq: id,
          },
        },
      }),
      transformResponse: (data: GetUserCohortsResponse) => {
        return {
          cohort_integrants:
            data.users[0]?.cohort_integrants.map(
              ({ subscriptions, duos, cohort, ...integrant }) => {
                const lastClassWithStatus = cohort.cohort_classes.find(
                  cohort_class =>
                    cohort_class.status === CohortClassStatus.PENDIENTE ||
                    cohort_class.status === CohortClassStatus.FINALIZADA ||
                    cohort_class.status === CohortClassStatus.ON_DEMAND,
                )

                return {
                  ...integrant,
                  subscription: subscriptions.at(0)?.id,
                  duo: duos.at(0)?.id,
                  cohort: {
                    ...cohort,
                    cohort_classes: lastClassWithStatus
                      ? [lastClassWithStatus]
                      : [],
                  },
                }
              },
            ) || [],
        }
      },
    }),
    getUserSubscriptions: builder.query<
      GetUserSubscriptionsTransformResponse,
      string
    >({
      query: id => ({
        document: gql`
          query UserSubscriptions($id: String) {
            subscriptions(
              where: { userId: { _eq: $id } }
              order_by: { createdAt: desc }
            ) {
              id
              userId
              activatedAt
              finishAt
              status
              checkoutLink
              invoices(
                where: {
                  _or: [
                    { status: { _eq: "OPEN" } }
                    { status: { _eq: "UNCOLLECTIBLE" } }
                  ]
                  paymentMethod: { _eq: "TRANSFER" }
                }
                order_by: { createdAt: asc }
                limit: 1
              ) {
                id
                status
              }
              duos {
                id
                invitationEmail
              }
              plan {
                name
                type
                installments
                product {
                  name
                }
                payment_method {
                  name
                  type
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      providesTags: ['Subscriptions'],
      transformResponse: (data: GetUserSubscriptionsResponse) =>
        data.subscriptions,
    }),
    getUserDuos: builder.query<GetUserDuosTransformResponse, string>({
      query: id => ({
        document: gql`
          query GetUserDuos($id: String) {
            duos(
              where: { userId: { _eq: $id } }
              order_by: { createdAt: desc }
            ) {
              id
              subscriptionOwner
              cohort_integrant {
                cohort {
                  id
                  code
                  days
                  startDate
                  type
                }
              }
              subscription {
                status
                plan {
                  product {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse: (data: GetUserDuosResponse) => data.duos,
    }),
    GetPendingContracts: builder.query<
      GetUserPendingContractsTransformResponse,
      string
    >({
      query: userId => ({
        document: gql`
          query GetUserPendingContracts($userId: String) {
            contracts(
              limit: 1
              where: { userId: { _eq: $userId }, status: { _eq: "PENDIENTE" } }
            ) {
              id
              subscriptionId
              status
              userId
            }
          }
        `,
        variables: {
          userId,
        },
      }),
      providesTags: ['Contracts'],
      transformResponse: (data: GetUserPendingContractsResponse) =>
        data.contracts.at(0),
    }),
  }),
})

export const {
  useGetUserCohortsQuery,
  useGetUserSubscriptionsQuery,
  useGetUserDuosQuery,
  useGetPendingContractsQuery,
} = userQueryApi
