import React, { useEffect, useRef } from 'react'
import Player from '@vimeo/player'
import { Box } from '@chakra-ui/react'

type VimeoPlayerProps = {
  vimeoId: number
  height?: number
  handleTimeUpdate: (percent: number) => void
}

export const VimeoPlayer = ({
  vimeoId,
  height = 360,
  handleTimeUpdate,
}: VimeoPlayerProps) => {
  const playerVimeo = useRef<HTMLDivElement>(null)

  const memoizedHandleTimeUpdate = React.useCallback(handleTimeUpdate, [])

  useEffect(() => {
    if (playerVimeo.current) {
      const player = new Player(playerVimeo.current, {
        id: +vimeoId,
        height: height || 560,
        width: undefined,
        transparent: false,
      })

      let updateAlreadyFired = false

      player.on('timeupdate', e => {
        if (e.percent > 0.7 && !updateAlreadyFired) {
          memoizedHandleTimeUpdate(e.percent)
          updateAlreadyFired = true
        }
      })

      player.loadVideo(vimeoId)

      return () => {
        player.off('timeupdate')
      }
    }
  }, [vimeoId, memoizedHandleTimeUpdate])

  const boxStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'fit-content',
  }

  return <Box style={boxStyles} ref={playerVimeo} />
}
