import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HStack, Stack, Text } from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import { ModulePresentation } from '@/education/shared/components/ModulePresentation'
import { ContentTemplate } from '@/education/shared/template/ContentTemplate'
import { Breadcrumb } from '@/shared/components/Breadcrumb'
import { Hero } from '@/shared/components/Hero'
import { Isologo } from '@/shared/components/Isologo'
import { HeroLoader } from '@/education/shared/components/loaders/HeroLoader'
import { HeaderNovu } from '@/shared/components/Novu'
import {
  GetAssignmentsByStudentsResponse,
  useGetAssignmentsByStudentQuery,
} from '../services/assignment.service'
import { useStudent } from '@/shared/hooks'

export const StudentAssignmentHero = () => {
  const { code } = useParams()
  const { studentId } = useStudent(code!)

  const { data, isLoading, error } = useGetAssignmentsByStudentQuery({
    code: code!,
    studentId: studentId!,
  })

  return (
    <Hero image='https://res.cloudinary.com/dcatzxqqf/image/upload/v1679871403/Galileo-Platform/Images/banner/assigmentHero_uqi30o.webp'>
      <ContentTemplate
        h='100%'
        display='flex'
        flexDir='column'
        justifyContent='space-around'
      >
        <HStack w='95%' justify='space-between'>
          <Breadcrumb />
          <HStack>
            <HeaderNovu />
            <Isologo />
          </HStack>
        </HStack>
        {isLoading ? (
          <HeroLoader withStatus />
        ) : (
          <HStack w='95%' justify='space-between' align='flex-end'>
            <Stack spacing={5}>
              <ModulePresentation title='Espacio de entregas' fontSize='4xl' />
            </Stack>
            <HStack spacing={3}>
              <Text fontSize='sm' textTransform='uppercase'>
                {
                  data?.users_assignments.filter(
                    assignment => assignment.status === 'APROBADA',
                  ).length
                }
                /{data?.users_assignments.length} Aprobadas
              </Text>
            </HStack>
          </HStack>
        )}
      </ContentTemplate>
    </Hero>
  )
}
