import React from 'react'
import { Skeleton, VStack, Box, Stack } from '@chakra-ui/react'
import { generateArrayWithRandomKeys } from '@/shared/utils'

export const SectionPickerLoader = () => {
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      w='100%'
      overflowY='hidden'
      alignItems='start'
      justifyContent='start'
      h='90vh'
    >
      <VStack spacing={6} align='center'>
        {generateArrayWithRandomKeys(4).map(key => (
          <VStack key={key} spacing={4} align='start'>
            <Skeleton height='20px' width='150px' borderRadius='md' />
            {generateArrayWithRandomKeys(4).map(subKey => (
              <Skeleton
                key={subKey}
                height='30px'
                width='200px'
                borderRadius='md'
              >
                <Box />
              </Skeleton>
            ))}
          </VStack>
        ))}
      </VStack>
    </Stack>
  )
}
