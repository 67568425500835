import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Collapse,
  Grid,
  GridItem,
  Heading,
  HStack,
  Stack,
} from '@chakra-ui/react'
import { Card, NucChevronUp } from '@nucba-coding/nucba-ui'
import { motion } from 'framer-motion'

import { CohortClassStatus } from '@/shared/enums'
import { slugifyCourseName } from '@/education/shared/utils'
import { useCoursePrefetch } from '../services/course.service'
import { useSessionState } from '@/shared/features/session.slice'
import { COHORT_TYPE } from '@/shared/types'

type Course = {
  id: string
  name: string
  image: string
  group: string | null
  isAsync: boolean
  classes: {
    cohort_classes: {
      status: CohortClassStatus
      date: Date
    }[]
  }[]
}

type AsyncCoursesProps = {
  courses: Course[]
  cohortType: COHORT_TYPE
}

const DEFAULT_GROUP = 'Otros'

export const AsyncCourses = ({ courses, cohortType }: AsyncCoursesProps) => {
  const groupedCourses = courses.reduce((acc, course) => {
    const group = course.group || DEFAULT_GROUP
    const currentCourses = acc.get(group) || []
    acc.set(group, [...currentCourses, course])
    return acc
  }, new Map<string, Course[]>())

  return (
    <Stack gap={8}>
      {Array.from(groupedCourses)
        .sort(([a], [b]) => {
          if (a === DEFAULT_GROUP) return -1
          if (b === DEFAULT_GROUP) return 1
          return 0
        })
        .map(([group, courses]) => (
          <CollapseCourse
            key={group}
            group={group}
            courses={courses}
            cohortType={cohortType}
          />
        ))}
    </Stack>
  )
}

function CollapseCourse({
  group,
  courses,
  cohortType,
}: {
  group: string
  courses: Course[]
  cohortType: COHORT_TYPE
}) {
  const [show, setShow] = useState(true)
  const handleToggle = () => setShow(!show)

  if (group === DEFAULT_GROUP) {
    return <CourseGrid courses={courses} cohortType={cohortType} />
  }

  const formatGroupName = (text: string) => {
    return text
      .replace(/-/g, ' ')
      .toLowerCase()
      .replace(/^\w/, char => char.toUpperCase())
  }

  return (
    <Box>
      <HStack align='baseline'>
        <Heading as='h4' fontWeight={400} fontSize={24}>
          {formatGroupName(group)}
        </Heading>
        <motion.button
          initial={false}
          animate={{ rotate: show ? '180deg' : '0deg' }}
          style={{
            maxWidth: 'min-content',
            cursor: 'pointer',
          }}
          onClick={handleToggle}
        >
          <NucChevronUp />
        </motion.button>
      </HStack>
      <Collapse style={{ marginTop: '20px' }} in={show}>
        <CourseGrid courses={courses} cohortType={cohortType} />
      </Collapse>
    </Box>
  )
}

function CourseGrid({
  courses,
  cohortType,
}: {
  courses: Course[]
  cohortType: COHORT_TYPE
}) {
  const navigate = useNavigate()
  const { id } = useSessionState()

  const prefetchCourseDetails = useCoursePrefetch('getCourseDetails')

  return (
    <Grid
      templateColumns={{
        base: 'repeat(auto-fit, 250px)',
        '2xl': 'repeat(auto-fit, 330px)',
      }}
      gap={10}
      mb={3}
    >
      {courses.map(course => (
        <GridItem
          key={course.id}
          onClick={() =>
            navigate(`curso/${slugifyCourseName(course.name)}`, {
              state: {
                courseId: course.id,
                courseName: course.name,
                cohortType: cohortType,
              },
            })
          }
          onMouseEnter={() =>
            prefetchCourseDetails({ courseId: course.id, userId: id })
          }
        >
          <Card
            w='auto'
            h={{ base: 260, '2xl': 400 }}
            title={course.name}
            bgImage={course.image}
          />
        </GridItem>
      ))}
    </Grid>
  )
}
