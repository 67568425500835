import React, { useState, useEffect } from 'react'
import {
  Stack,
  Grid,
  Text,
  HStack,
  VStack,
  GridItem,
  Heading,
} from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'

import { SectionPicker } from '../components/SectionPicker'
import { CardPost } from '../components/CardPost'
import {
  useGetPostsQuery,
  useGetGroupsQuery,
} from '../services/blog-query.service'
import { PostCardLoader } from '@/education/shared/components/loaders/BlogCardsLoader'
import styled from '@emotion/styled'
import { SectionPickerLoader } from '@/education/shared/components/loaders/SectionPickLoader'

export const SectionBlogContainerWithScroll = styled(Stack)`
  width: 95%;
  height: 90vh;
  margin: 0.5rem 0;
  padding-right: 1rem;

  overflow-y: ${({ overflowY }) => overflowY || 'scroll'};

  &::-webkit-scrollbar {
    width: 12px;
    margin-left: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`

export const Blog = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeSection, setActiveSection] = useState<string | null>(null)

  const spaceSlug = searchParams.get('section') || 'html'
  const { data: postsData, isLoading, error } = useGetPostsQuery(spaceSlug)
  const { data: groupsData, isLoading: isLoadingGroups } = useGetGroupsQuery()

  const spaceImage = groupsData?.groups
    .flatMap(group => group.spaces)
    .find(space => space.slug === spaceSlug)?.coverage_image_url

  const handlePick = (label: string) => {
    setActiveSection(label)
    setSearchParams({ section: label })
  }

  useEffect(() => {
    const sectionFromParams = searchParams.get('section')
    const validSections =
      groupsData?.groups.flatMap(group =>
        group.spaces.map(space => space.slug),
      ) || []

    if (
      groupsData &&
      sectionFromParams &&
      validSections.includes(sectionFromParams)
    ) {
      setActiveSection(sectionFromParams)
    } else if (groupsData && !sectionFromParams) {
      setSearchParams({ section: 'html' })
      setActiveSection('html')
    } else if (
      groupsData &&
      sectionFromParams &&
      !validSections.includes(sectionFromParams)
    ) {
      setSearchParams({ section: 'html' })
      setActiveSection('html')
    }
  }, [searchParams, groupsData])

  if (error) return <Text>Error: {error.toString()}</Text>

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      pl={20}
      gap={12}
      w='100%'
      overflowX='hidden'
      alignItems='center'
      justifyContent='center'
      minHeight='100vh'
    >
      <Stack spacing={6} w={{ base: '100%', md: 'auto' }}>
        {isLoadingGroups ? (
          <SectionPickerLoader />
        ) : (
          <SectionPicker
            onPick={handlePick}
            activeSection={activeSection || 'html'}
            groups={groupsData?.groups || []}
          />
        )}
      </Stack>

      <Stack w={{ base: '100%' }} overflow='hidden'>
        <SectionBlogContainerWithScroll
          w={{ base: '100%', md: '95%' }}
          spacing={4}
        >
          <Heading size='xl'>
            {
              groupsData?.groups
                .find(group =>
                  group.spaces.some(space => space.slug === activeSection),
                )
                ?.spaces.find(space => space.slug === activeSection)?.name
            }
          </Heading>
          <Grid
            templateColumns={{
              base: '1fr',
              sm: 'repeat(auto-fill, minmax(250px, 1fr))',
            }}
            gap={6}
            w='100%'
            maxW='100%'
          >
            {isLoading ? (
              [...Array(6)].map((_, idx) => <PostCardLoader key={idx} />)
            ) : postsData?.posts_connection.nodes.length ? (
              postsData.posts_connection.nodes.map(post => (
                <CardPost
                  key={post.slug}
                  title={post.name}
                  date={post.circle_published_at}
                  coverageImage={post.coverage_image_url ?? spaceImage}
                  postSlug={post.slug}
                  spaceSlug={spaceSlug}
                />
              ))
            ) : (
              <GridItem colSpan={3}>
                <Text textAlign='center'>
                  Todavía no hay notas en la sección: {activeSection || 'html'}{' '}
                  🥲
                </Text>
              </GridItem>
            )}
          </Grid>
        </SectionBlogContainerWithScroll>
      </Stack>
    </Stack>
  )
}
