import React from 'react'
import { Skeleton, VStack, Box } from '@chakra-ui/react'
import { generateArrayWithRandomKeys } from '@/shared/utils'

export const PostCardLoader: React.FC = () => {
  return (
    <VStack spacing={6}>
      {generateArrayWithRandomKeys(6).map(key => (
        <Box key={key} w='100%'>
          <Skeleton height='200px' borderRadius='md' />
          <Skeleton mt={4} height='20px' width='80%' borderRadius='md' />
          <Skeleton mt={2} height='16px' width='60%' borderRadius='md' />
        </Box>
      ))}
    </VStack>
  )
}
