import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  HStack,
  Text,
  Avatar,
  Select,
  Link,
  Stack,
  Switch,
  VStack,
} from '@chakra-ui/react'
import { Button } from '@nucba-coding/nucba-ui'

import {
  GetAssignmentsByStudentsResponse,
  useAssignmentPrefetch,
  useGetAssignmentsByStudentQuery,
} from '../services/assignment.service'
import { CardContainer } from '@/education/shared/components/ClassCardContainer'
import { ClassCardContent } from '@/education/shared/components/ClassCardContent'
import { assignmentIsDisable, formatStatus } from '@/education/shared/utils'
import { AssignmentStatus, CohortClassStatus } from '@/shared/enums'
import { ASSIGNMENT_STATUS_COLOR } from '@/shared/constants'
import { DateTime } from '@/shared/helpers'
import { ListLoader } from '@/education/shared/components/loaders/ListLoader'
import { useStudent } from '@/shared/hooks'
import styled from '@emotion/styled'

export const SectionContainerWithScroll = styled(Stack)`
  width: 100%;
  height: 47vh;

  overflow-y: ${({ overflowY }) => overflowY || 'scroll'};

  &::-webkit-scrollbar {
    width: 12px;
    margin-left: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`

const assigmentOptions: Record<AssignmentStatus, string> = {
  APROBADA: 'Aprobada',
  ENTREGADA: 'Entregada',
  EN_REVISION: 'En revisión',
  PENDIENTE: 'Pendiente',
  VENCIDA: 'Vencida',
}

const isAssignmentBeforeExpulsion = (
  expelledAt: Date,
  assignmentDate: Date,
) => {
  return DateTime.createFromDate(new Date(assignmentDate)).lowerThan(
    DateTime.createFromDate(new Date(expelledAt)),
  )
}

export const ListOfStudentsAssignments = () => {
  const { code } = useParams()
  const { studentId, expelledAt } = useStudent(code!)
  const navigate = useNavigate()

  const { data, isLoading, error } = useGetAssignmentsByStudentQuery({
    code: code!,
    studentId: studentId!,
  })

  const prefetchAssignmentDetails = useAssignmentPrefetch(
    'getAssignmentDetails',
    {
      force: false,
    },
  )

  const [filter, setFilter] = useState<AssignmentStatus | null>(null)
  const [isIntegrative, setIsIntegrative] = useState(true)

  const cohortType = data?.users_assignments[0]?.cohort.type

  let usersAssignments = data?.users_assignments
  console.log('usersAssignments', usersAssignments)

  if (filter) {
    usersAssignments = usersAssignments?.filter(
      assignment => assignment.status === filter,
    )
  }

  usersAssignments = usersAssignments?.filter(
    assignment =>
      assignment.exercise &&
      (isIntegrative
        ? assignment.exercise?.isIntegrative
        : !assignment.exercise?.isIntegrative),
  )

  usersAssignments = usersAssignments?.filter(assignment => {
    const assignmentDate = assignment.exercise.class.cohort_classes[0]?.date
    return expelledAt
      ? isAssignmentBeforeExpulsion(
          new Date(expelledAt),
          new Date(assignmentDate),
        )
      : true
  })

  return (
    <Stack spacing={5} h='47vh'>
      <HStack justify='space-between' w='100%' mt={4}>
        <Stack w='20%'>
          <Text fontSize='xl'>🛠️ Entregas</Text>
        </Stack>
        <HStack w='40%' alignItems='end' spacing={6}>
          {!isLoading && cohortType !== 'ON_DEMAND' && (
            <HStack mb={2}>
              <Text whiteSpace='nowrap'>Tipo de entrega:</Text>
              <HStack>
                <Text>{isIntegrative ? 'Integradores' : 'Entregas'}</Text>
                <Switch
                  isChecked={isIntegrative}
                  onChange={() => setIsIntegrative(!isIntegrative)}
                  colorScheme='teal'
                />
              </HStack>
            </HStack>
          )}
          <VStack alignItems='self-start' w='100%' spacing={3}>
            <Text whiteSpace='nowrap'>Filtrar por estado:</Text>
            <Select
              focusBorderColor='inherit'
              placeholder='Todas'
              onChange={e => setFilter(e.target.value as AssignmentStatus)}
            >
              {Object.entries(assigmentOptions).map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
            </Select>
          </VStack>
        </HStack>
      </HStack>
      <SectionContainerWithScroll flexGrow={1} spacing={5} overflowY='unset'>
        {isLoading ? (
          <ListLoader />
        ) : usersAssignments?.length ? (
          usersAssignments.map(
            ({
              id,
              status,
              exercise,
              cohortIntegrantByStudentid: {
                user: { profiles },
              },
              assignmentStatus_changes,
            }) => (
              <CardContainer key={id} cursor='auto !important'>
                <HStack width='97%' spacing={4}>
                  <Avatar
                    name={`${exercise.class.course.name}`}
                    w={10}
                    h={10}
                    borderRadius={10}
                  />
                  <ClassCardContent heading={`${exercise.name}`}>
                    <HStack spacing={5}>
                      <HStack fontSize='sm' gap={3} divider={<span>|</span>}>
                        {assignmentStatus_changes.length && (
                          <Text color='#AFAFAF'>
                            Entregado{' '}
                            {DateTime.createFromDate(
                              new Date(assignmentStatus_changes[0].updatedAt),
                            ).formatDate({
                              dateStyle: 'medium',
                              timeStyle: 'short',
                            })}
                          </Text>
                        )}
                        <HStack>
                          <Text color={ASSIGNMENT_STATUS_COLOR[status]}>
                            {formatStatus(status)}
                          </Text>

                          {exercise.class.cohort_classes.at(0)?.status !==
                            CohortClassStatus.ON_DEMAND &&
                            !assignmentStatus_changes.length && (
                              <Text fontSize='xs'>
                                |{' '}
                                {DateTime.createFromString(
                                  exercise.class.cohort_classes[0].date!,
                                  false,
                                ).formatDate({
                                  dateStyle: 'long',
                                })}
                              </Text>
                            )}
                        </HStack>
                      </HStack>
                      <Button
                        textTransform='uppercase'
                        borderRadius={20}
                        size='sm'
                        fontSize='sm'
                        onMouseEnter={() => {
                          prefetchAssignmentDetails({
                            assignmentId: id,
                            code: code!,
                          })
                        }}
                        onClick={() =>
                          navigate(
                            `/camada/${code}/curso/${exercise.class.course.name}/asignacion/${id}`,
                          )
                        }
                        isDisabled={
                          exercise.class.cohort_classes.at(0)?.status !==
                          CohortClassStatus.ON_DEMAND
                            ? assignmentIsDisable(
                                DateTime.createFromString(
                                  exercise.class.cohort_classes[0].date!,
                                  false,
                                ).getDate(),
                              )
                            : false
                        }
                      >
                        Ver entrega
                      </Button>
                    </HStack>
                  </ClassCardContent>
                </HStack>
              </CardContainer>
            ),
          )
        ) : (
          <Stack flexGrow={1} justify='center'>
            <Text textAlign='center' fontSize='xl'>
              {filter
                ? `No hay entregas ${assigmentOptions[filter].toLowerCase()}`
                : 'No hay entregas disponibles'}
            </Text>
          </Stack>
        )}
      </SectionContainerWithScroll>
    </Stack>
  )
}
