import React from 'react'
import { Stack, Text } from '@chakra-ui/react'
import { SectionContainerWithScroll } from '@/education/shared/components/SectionContainerWithScroll'
import { ContentTemplate } from '@/education/shared/template/ContentTemplate'
import { ListOfStudentsAssignments } from '@/education/assignments/components/ListOfStudentsAssignments'
import { useParams } from 'react-router-dom'
import { useStudent } from '@/shared/hooks'
import { useGetAssignmentsByStudentQuery } from '@/education/assignments/services/assignment.service'
import { StudentAssignmentHero } from '@/education/assignments/components/StudentAssignmentHero'

export const Assignments = () => {
  return (
    <>
      <StudentAssignmentHero />
      <ContentTemplate>
        <SectionContainerWithScroll spacing={5}>
          <ListOfStudentsAssignments />
        </SectionContainerWithScroll>
      </ContentTemplate>
    </>
  )
}
