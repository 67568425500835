import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Center, Grid, GridItem, Text, VStack } from '@chakra-ui/react'
import { Card } from '@nucba-coding/nucba-ui'

import { PageHeading } from '../../shared/components/PageHeading'
import { slugifyCourseName } from '../../shared/utils'
import {
  useGetCohortCoursesFirstClassesQuery,
  useGetCohortCoursesQuery,
} from '../services/cohort.service'
import { useStudent } from '@/shared/hooks'
import { useCoursePrefetch } from '../services/course.service'
import { useAppSelector } from '@/shared/app/hooks'
import { sessionState } from '@/shared/features/session.slice'
import { CohortCoursesLoader } from '@/education/shared/components/loaders/CohortCoursesLoader'
import { CohortClassStatus } from '@/shared/enums'
import { DateTime } from '@/shared/helpers'
import { COHORT_TYPE } from '@/shared/types'
import { AsyncCourses } from './AsyncCourses'

interface CohortClass {
  status: CohortClassStatus
  date: Date
}

interface Class {
  cohort_classes: CohortClass[]
}

interface Course {
  id: string
  name: string
  image: string
  isAsync: boolean | string
  classes: Class[]
}

interface ProductCourse {
  course: Course
}

const isCourseDisableDueToStudentExpelled = (
  expelledAt: Date,
  courseFirstClassDate: Date,
) => {
  return DateTime.createFromDate(new Date(expelledAt!)).lowerThan(
    DateTime.createFromDate(new Date(courseFirstClassDate)),
  )
}

export const CohortCourses = () => {
  const { code } = useParams()
  const navigate = useNavigate()
  const { studentId, expelledAt } = useStudent(code!)

  const { id, isStaff } = useAppSelector(sessionState)

  const prefetchCourseDetails = useCoursePrefetch('getCourseDetails')

  const { data, isLoading } = useGetCohortCoursesQuery({
    code: code!,
    studentId,
  })

  const { data: coursesFirstClasses, isLoading: isCoursesFirstClassesLoading } =
    useGetCohortCoursesFirstClassesQuery(
      {
        code: code!,
        studentId,
      },
      {
        skip: !expelledAt,
      },
    )

  const syncCourses =
    data?.cohorts[0]._products_cohorts
      .flatMap(({ product }) => product._product_courses)
      .filter(({ course }) => !course.isAsync) ?? []

  const asyncCourses =
    data?.cohorts[0]._products_cohorts
      .flatMap(({ product }) => product._product_courses)
      .filter(({ course }) => course.isAsync) ?? []

  return (
    <>
      {syncCourses.length > 0 && (
        <>
          <PageHeading mb={3}>Módulos en vivo</PageHeading>
          <Grid
            templateColumns={{
              base: 'repeat(auto-fit, 250px)',
              '2xl': 'repeat(auto-fit, 330px)',
            }}
            gap={10}
            mb={3}
          >
            {/* SYNC COURSES */}
            {isLoading || isCoursesFirstClassesLoading ? (
              <CohortCoursesLoader />
            ) : (
              syncCourses.map(({ course }: ProductCourse, index) => {
                const product = data?.cohorts[0]._products_cohorts[0].product

                const isAble =
                  isStaff ||
                  !index ||
                  product!._product_courses[index - 1].course.classes[0]
                    .cohort_classes[0]?.status === CohortClassStatus.FINALIZADA

                if (expelledAt) {
                  const existCourse =
                    coursesFirstClasses?._products_cohorts.flatMap(
                      ({ product }) =>
                        product._product_courses.filter(
                          ({ course: _course }) => _course.id === course.id,
                        ),
                    )

                  if (existCourse) {
                    const firstClassDate =
                      existCourse[0].course.classes[0].cohort_classes[0].date

                    const isDisabled = isCourseDisableDueToStudentExpelled(
                      expelledAt,
                      firstClassDate,
                    )

                    if (isDisabled) {
                      return null
                    }
                  }
                }

                return isAble ? (
                  <GridItem
                    key={course.id}
                    onClick={() =>
                      navigate(`curso/${slugifyCourseName(course.name)}`, {
                        state: {
                          courseId: course.id,
                          courseName: course.name,
                          cohortType: data?.cohorts.at(0)?.type,
                        },
                      })
                    }
                    onMouseEnter={() =>
                      prefetchCourseDetails({ courseId: course.id, userId: id })
                    }
                  >
                    <Card
                      w='auto'
                      h={{ base: 260, '2xl': 400 }}
                      title={course.name}
                      bgImage={course.image}
                    />
                  </GridItem>
                ) : (
                  <GridItem key={course.id} pos='relative' cursor='not-allowed'>
                    <Card
                      w='auto'
                      h={{ base: 260, '2xl': 400 }}
                      title={course.name}
                      bgImage={course.image}
                    />
                    <Center
                      pos='absolute'
                      inset={0}
                      w='full'
                      h={{ base: 260, '2xl': 400 }}
                      backdropFilter='blur(3px);'
                    >
                      <Text
                        fontSize='xl'
                        textAlign='center'
                        wordBreak='keep-all'
                      >
                        Próximamente disponible
                      </Text>
                    </Center>
                  </GridItem>
                )
              })
            )}
          </Grid>
        </>
      )}

      {!expelledAt ? (
        <Box mb={10}>
          {asyncCourses.length > 0 ? (
            <PageHeading mb={5} mt={10}>
              Módulos asíncronos
            </PageHeading>
          ) : null}

          {/* ASYNC COURSES */}
          {isLoading || isCoursesFirstClassesLoading ? (
            <></>
          ) : (
            asyncCourses.length > 0 && (
              <AsyncCourses
                courses={asyncCourses.map(({ course }) => course)}
                cohortType={data?.cohorts.at(0)?.type as COHORT_TYPE}
              />
            )
          )}
        </Box>
      ) : (
        <VStack marginBlock={10} alignItems='self-start'>
          <Text fontSize='xl'>
            No tienes acceso al resto de los cursos debido a tu acceso
            restingido. 🥲
          </Text>
          <Text fontSize='xl'>
            Si crees que es un error o tenes alguna consulta, comunícate con
            secretaría. 🙌
          </Text>
        </VStack>
      )}
    </>
  )
}
