import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Stack, StyleProps, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ContentTemplate } from '../../shared/template/ContentTemplate'
import { ClassCard } from './ClassCard'
import {
  useGetCohortClassesByCourseQuery,
  useGetCohortNextClassQuery,
} from '../services/cohort.service'
import { useStudent } from '@/shared/hooks/useStudent'
import { ListLoader } from '@/education/shared/components/loaders/ListLoader'
import { CohortClassStatus } from '@/shared/enums'
import { DateTime } from '@/shared/helpers'
import { useGetCourseModulesQuery } from '../services/course.service'

type ListOfClassesProps = {
  courseId?: string
  wrapperStyles?: StyleProps
  simplifiedVersion?: boolean
  withScroll?: boolean
}

const isClassDisableDueToStudentExpelled = (
  expelledAt: Date,
  classDate: Date,
) => {
  return DateTime.createFromDate(new Date(expelledAt!)).lowerThan(
    DateTime.createFromDate(new Date(classDate)),
  )
}

export const ListOfClasses = ({
  courseId,
  withScroll = false,
  wrapperStyles,
  simplifiedVersion = false,
}: ListOfClassesProps) => {
  const { code, classId } = useParams()
  const { state } = useLocation()
  const { studentId, expelledAt } = useStudent(code!)
  console.log('expelledAt', expelledAt)
  const { data: nextClassId } = useGetCohortNextClassQuery(code!, {
    skip: simplifiedVersion,
  })

  const { data, isLoading } = useGetCohortClassesByCourseQuery({
    code: code!,
    courseId: state ? state.courseId : courseId || '',
    studentId,
  })

  const { data: courseModules, isLoading: isLoadingCourseModules } =
    useGetCourseModulesQuery({
      courseId: state ? state.courseId : courseId || '',
    })
  const courseHasModules = courseModules && courseModules.modules.length > 0

  const isSomeClassInCourse = data?.cohort_classes.some(
    _class => _class.status === CohortClassStatus.EN_CURSO,
  )
  const [expanded, setExpanded] = useState<false | number>(false)

  return (
    <ContentTemplate {...wrapperStyles}>
      <ClassesContainer
        spacing={5}
        align='flex-start'
        withScroll={withScroll || simplifiedVersion}
        simplifiedVersion={simplifiedVersion}
        paddingBlock={withScroll ? '10px' : '20px'}
      >
        {isLoading || isLoadingCourseModules ? (
          <ListLoader withScroll={withScroll || simplifiedVersion} />
        ) : courseHasModules ? (
          courseModules.modules.map((module, i) => (
            <React.Fragment key={module.id}>
              <Text
                as='h4'
                pt={i === 0 || simplifiedVersion ? 0 : 2}
                fontSize={simplifiedVersion ? 'md' : 'lg'}
              >
                {module.name}
              </Text>
              <ClassesContainer
                spacing={5}
                align='flex-start'
                withScroll={false}
                simplifiedVersion={simplifiedVersion}
              >
                {module.classes.map((_class, index) => {
                  const cohortClass = data?.cohort_classes.find(
                    cohortClass => cohortClass.class.id === _class.id,
                  )

                  if (!cohortClass) return null

                  return (
                    <ClassCard
                      key={_class.id}
                      i={index}
                      cohortClassID={cohortClass.id}
                      expanded={expanded}
                      setExpanded={setExpanded}
                      classDetail={{ ...cohortClass.class }}
                      date={cohortClass.date}
                      status={cohortClass.status}
                      remarkClass={
                        simplifiedVersion
                          ? classId === _class.id
                          : isSomeClassInCourse
                            ? cohortClass.status === CohortClassStatus.EN_CURSO
                            : nextClassId?.id === _class.id
                      }
                      simplifiedVersion={simplifiedVersion}
                      studentAttendance={
                        cohortClass.student_attendances[0]?.status
                      }
                      courseIsAsync={cohortClass.class.course.isAsync}
                      cohortType={cohortClass.cohort.type}
                    />
                  )
                })}
              </ClassesContainer>
            </React.Fragment>
          ))
        ) : (
          data?.cohort_classes.map(
            (_class, index) =>
              (!!expelledAt &&
                isClassDisableDueToStudentExpelled(
                  expelledAt,
                  _class.date,
                )) || (
                <ClassCard
                  key={_class.id}
                  cohortClassID={_class.id}
                  i={index}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  classDetail={{ ..._class.class }}
                  date={_class.date}
                  status={_class.status}
                  remarkClass={
                    simplifiedVersion
                      ? classId === _class.id
                      : isSomeClassInCourse
                        ? _class.status === CohortClassStatus.EN_CURSO
                        : nextClassId?.id === _class.id
                  }
                  simplifiedVersion={simplifiedVersion}
                  courseIsAsync={_class.class.course.isAsync}
                  cohortType={_class.cohort.type}
                  studentAttendance={_class.student_attendances[0]?.status}
                />
              ),
          )
        )}
      </ClassesContainer>
    </ContentTemplate>
  )
}

const ClassesContainer = styled(Stack)`
  width: 95%;
  height: ${({ withScroll, simplifiedVersion }) =>
    withScroll && simplifiedVersion ? '80vh' : withScroll ? '47vh' : 'auto'};

  align-items: ${({ simplifiedVersion }) =>
    simplifiedVersion ? 'flex-start' : ''};
  padding-bottom: ${({ withScroll }) => (withScroll ? '10px' : '20px')};
  padding-left: ${({ withScroll }) => (withScroll ? '2px' : 0)};

  overflow-y: ${({ withScroll }) => (withScroll ? 'scroll' : 'unset')};

  &::-webkit-scrollbar {
    width: 8px;
    margin-left: 3rem;
  }

  &::-webkit-scrollbar-thumb {
    background: #17181e;
    border-radius: 30px;
  }
`
