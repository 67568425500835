import React from 'react'
import { Box, Image, Text, Stack, Avatar } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

interface CardPostProps {
  title: string
  date: string
  coverageImage: string
  postSlug: string
  spaceSlug: string
}

export const CardPost: React.FC<CardPostProps> = ({
  title,
  date,
  coverageImage,
  postSlug,
  spaceSlug,
}) => {
  return (
    <Box
      as={Link}
      to={`/blog/${spaceSlug}/${postSlug}`}
      bg='gray.700'
      borderRadius='lg'
      boxShadow='lg'
      color='white'
      position='relative'
      overflow='hidden'
      w='100%'
      maxW={{ base: '100%', md: '330px' }}
    >
      <Image
        mb={4}
        src={coverageImage}
        alt='Post Image'
        width='100%'
        height='150px'
        objectFit='cover'
      />
      <Avatar
        src='https://res.cloudinary.com/dcatzxqqf/image/upload/v1681409550/Galileo-Platform/Assets/LogoI_gdk6aa_1_1_ep1o8l.webp'
        size='sm'
        position='absolute'
        top='130px'
        left='10px'
        backgroundColor='black'
        border='2px solid white'
      />
      <Stack spacing={2} mt={6} p={4}>
        <Text fontWeight='bold'>{title}</Text>
        <Text fontSize='sm' color='gray.400'>
          NUCBA | {date.split('T')[0]}
        </Text>
      </Stack>
    </Box>
  )
}
