import React, { ReactNode } from 'react'
import {
  HStack,
  Icon,
  Stack,
  StyleProps,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'

import { NucCheckCircle } from '@nucba-coding/nucba-ui'
import { ONDEMAND_CLASS_STATUS_COLOR } from '@/shared/constants'

type ClassCardContentProps = {
  heading: string
  description?: string
  children?: ReactNode | null
  textStyles?: StyleProps
  simplifiedVersion?: boolean
  showIcon?: boolean
  iconIsActive?: boolean
}

export const ClassCardContent = ({
  children = null,
  heading,
  description,
  textStyles,
  simplifiedVersion,
  iconIsActive,
  showIcon,
}: ClassCardContentProps) => {
  const [isSmallerThan1425] = useMediaQuery('(max-width: 1425px)')

  return (
    <HStack
      w='97%'
      minH='40px'
      justify='space-between'
      pl={simplifiedVersion ? 3 : 0}
      flexDir={simplifiedVersion && isSmallerThan1425 ? 'column' : 'row'}
      alignItems={simplifiedVersion && isSmallerThan1425 ? 'flex-start' : ''}
      gap={simplifiedVersion ? 3 : ''}
    >
      <Stack spacing={0} justifyContent={'center'} {...textStyles}>
        <HStack>
          {showIcon && (
            <Icon
              as={NucCheckCircle}
              color={
                iconIsActive
                  ? ONDEMAND_CLASS_STATUS_COLOR.RECORDING_VIEWED
                  : ONDEMAND_CLASS_STATUS_COLOR.RECORDING_NOT_VIEWED
              }
            />
          )}
          <Text
            fontSize={isSmallerThan1425 && simplifiedVersion ? 'small' : 'sm'}
            fontWeight={800}
          >
            {heading}
          </Text>
        </HStack>
        {description && <Text fontSize='xs'>{description} </Text>}
      </Stack>
      {children}
    </HStack>
  )
}
