export type Product = {
  id: string
  name: string
  type: PRODUCTS_TYPES
  hasAvailableCohort: boolean
  hasPlansEnable: boolean
  hasDuoPlan: boolean
}

export type CohortProduct = {
  id: string
  code: string
  days: number[]
  startDate: string
  type: COHORT_TYPE
}

export enum PAYMENT_METHODS_TYPES {
  TRANSFER = 'TRANSFER',
  MERCADO_PAGO = 'MERCADO_PAGO',
  INCREASE = 'INCREASE',
}

export enum Currencies {
  ARS = 'ARS',
  USD = 'USD',
}

export enum PRODUCTS_TYPES {
  CARRERA = 'CARRERA',
  CURSO = 'CURSO',
  ON_DEMAND = 'ON_DEMAND',
}

export enum PLANS_TYPES {
  BECA = 'BECA',
}

export enum SUBSCRIPTIONS_TYPES {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  WAITING_PAYMENT_CONFIRMATION = 'WAITING_PAYMENT_CONFIRMATION',
  CANCELLED = 'CANCELLED',
  FINISHED = 'FINISHED',
  PAUSED = 'PAUSED',
}

export enum PAYMENT_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
}

export enum INVOICE_STATUS {
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  CANCELLED = 'CANCELLED',
  PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS',
}

export enum CONTRACT_STATUS {
  PENDIENTE = 'PENDIENTE',
  FIRMADO = 'FIRMADO',
}

export enum COHORT_STATUS {
  INSCRIPCIONES_ABIERTAS = 'INSCRIPCIONES_ABIERTAS',
  INSCRIPCIONES_CERRADAS = 'INSCRIPCIONES_CERRADAS',
}

export enum SUBSCRIPTION_ADDITIONAL_TYPES {
  CAMBIO_CAMADA = 'CAMBIO_CAMADA',
  MEJORA_ACCESO = 'MEJORA_ACCESO',
}

export enum COHORT_TYPE {
  VIVO = 'VIVO',
  ON_DEMAND = 'ON_DEMAND',
}

export enum ATTENDANCE_STATUS {
  ABSENT = 'ABSENT',
  PRESENT = 'PRESENT',
  RECORDING_VIEWED = 'RECORDING_VIEWED',
}
